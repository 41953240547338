<template>
  <div class="information">
    <div v-if="detailInfo.orderInfo.tprocAudit" class="information-container">
      <p style="color:rgb(86, 192, 117);font-size:20px">
        <i class="el-icon-success"></i>
        <span style="margin-left:14px">订单提交成功!</span>
      </p>
      <div class="include">
        <div class="line">
          <p>
            <span class="title">订单号：</span>
            <span>{{ detailInfo.orderInfo.orderSn }}</span>
          </p>
          <p>
            <span class="title">订单状态：</span>
            <span>{{ orderStatusMsg }}</span>
            <span v-if="detailInfo.orderInfo.tprocAudit.approveStatus == '1'" style="color:red">(待审核)</span>
            <el-button
              size="mini"
              v-if="$route.query.isHandleShow && isShowOperate && detailInfo.orderInfo.tprocAudit.approveStatus == '0'"
              style="margin-left: 18px;vertical-align: middle;"
              type="primary"
              @click="myScroll"
              >{{ getOperateName(detailInfo.orderInfo.orderStatus) }}</el-button
            >
          </p>
          <p>
            <span class="title">订单金额：</span>
            <span style="color:#ff4400;font-size:16px">{{ detailInfo.orderInfo.orderPrice && detailInfo.orderInfo.orderPrice.toFixed(2) | formatPrice }}(元)</span>
          </p>
        </div>
        <div class="line">
          <p>
            <span class="title">订单模式：</span>
            <span>{{ detailInfo.orderInfo.orderProcess === 'HK' ? '先货后款' : '先款后货' }}</span>
          </p>
          <p>
            <span class="title">付款方式：</span>
            <span>{{ detailInfo.orderInfo.payWay || '-' }}</span>
          </p>
          <p>
            <span class="title">交货方式：</span>
            <span>{{ detailInfo.orderInfo.deliveryWay || '-' }}</span>
          </p>
        </div>
      </div>
      <p class="information-title">每个页面操作记录，请看页面下方的操作记录查看</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderInformation',
  props: {
    detailInfo: {
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {
    isShowOperate() {
      let flag = false;
      if (this.$route.query.customer === 'buyder') {
        ['DSH', 'DZF'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
        // 判断合同的签署按钮显示
        if (this.detailInfo.orderInfo.contractStatus == '0' && this.detailInfo.orderInfo.orderStatus == 'DQS') {
          flag = false;
        }
        if (this.detailInfo.orderInfo.contractStatus == '1' && this.detailInfo.orderInfo.orderStatus == 'DQS') {
          flag = true;
        }
      }
      if (this.$route.query.customer === 'sellder') {
        ['DFH', 'DKP'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
        // 判断合同的签署按钮显示
        if (this.detailInfo.orderInfo.orderStatus == 'DQS') {
          flag = true;
        }
      }
      return flag;
    },
    orderStatusMsg() {
      if (this.detailInfo.orderInfo.orderStatus === 'DFH') {
        return '待发货';
      } else if (this.detailInfo.orderInfo.orderStatus === 'DSH') {
        return '待签收';
      } else if (this.detailInfo.orderInfo.orderStatus === 'DKP') {
        return '待开发票';
      } else if (this.detailInfo.orderInfo.orderStatus === 'DZF') {
        return '待支付';
      } else if (this.detailInfo.orderInfo.orderStatus === 'ZFC') {
        return '支付待确认';
      } else if (this.detailInfo.orderInfo.orderStatus === 'END') {
        return '交易完成';
      } else if (this.detailInfo.orderInfo.orderStatus === 'QXC') {
        return '取消待确认';
      } else if (this.detailInfo.orderInfo.orderStatus === 'QXS') {
        return '取消成功';
      } else if (this.detailInfo.orderInfo.orderStatus === 'DQS') {
        return '合同未签署';
      } else if (this.detailInfo.orderInfo.orderStatus === 'DFWQ') {
        return '对方未签署';
      }
    }
  },
  methods: {
    getOperateName(data) {
      switch (data) {
        case 'DQS':
          return '签合同';
        case 'DSH':
          return '签收';
        case 'DKP':
          return '开发票';
        case 'DZF':
          return '支付';
        case 'DFH':
          return '发货';
      }
    },
    myScroll() {
      this.$emit('scrollToTarget');
    }
  }
};
</script>
<style lang="less" scoped>
.information {
  position: relative;
  background-color: #fff;
  padding: 20px 20px 35px 20px;
  &-container {
    padding: 25px;
    background-color: #f3ffff;
    .include {
      width: 500px;
      display: flex;
      justify-content: space-between;
    }
    .line {
      .title {
        color: #b4b8b8;
      }
      p {
        margin-top: 27px;
      }
    }
  }
  &-title {
    position: absolute;
    bottom: 10px;
    left: 20px;
  }
}
</style>
