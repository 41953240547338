var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "information" }, [
    _vm.detailInfo.orderInfo.tprocAudit
      ? _c("div", { staticClass: "information-container" }, [
          _vm._m(0),
          _c("div", { staticClass: "include" }, [
            _c("div", { staticClass: "line" }, [
              _c("p", [
                _c("span", { staticClass: "title" }, [_vm._v("订单号：")]),
                _c("span", [_vm._v(_vm._s(_vm.detailInfo.orderInfo.orderSn))]),
              ]),
              _c(
                "p",
                [
                  _c("span", { staticClass: "title" }, [_vm._v("订单状态：")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderStatusMsg))]),
                  _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "1"
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("(待审核)"),
                      ])
                    : _vm._e(),
                  _vm.$route.query.isHandleShow &&
                  _vm.isShowOperate &&
                  _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "0"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "18px",
                            "vertical-align": "middle",
                          },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.myScroll },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getOperateName(
                                _vm.detailInfo.orderInfo.orderStatus
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("p", [
                _c("span", { staticClass: "title" }, [_vm._v("订单金额：")]),
                _c(
                  "span",
                  { staticStyle: { color: "#ff4400", "font-size": "16px" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatPrice")(
                          _vm.detailInfo.orderInfo.orderPrice &&
                            _vm.detailInfo.orderInfo.orderPrice.toFixed(2)
                        )
                      ) + "(元)"
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("p", [
                _c("span", { staticClass: "title" }, [_vm._v("订单模式：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detailInfo.orderInfo.orderProcess === "HK"
                        ? "先货后款"
                        : "先款后货"
                    )
                  ),
                ]),
              ]),
              _c("p", [
                _c("span", { staticClass: "title" }, [_vm._v("付款方式：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.detailInfo.orderInfo.payWay || "-")),
                ]),
              ]),
              _c("p", [
                _c("span", { staticClass: "title" }, [_vm._v("交货方式：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.detailInfo.orderInfo.deliveryWay || "-")),
                ]),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "information-title" }, [
            _vm._v("每个页面操作记录，请看页面下方的操作记录查看"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticStyle: { color: "rgb(86, 192, 117)", "font-size": "20px" } },
      [
        _c("i", { staticClass: "el-icon-success" }),
        _c("span", { staticStyle: { "margin-left": "14px" } }, [
          _vm._v("订单提交成功!"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }